
/**
 *  Get values from process.env.VUE_APP*
 *
 */
export class VueEnvHelper {
    prefix = '' // Without a prefix values are taken from VUE_APP_* directly

    constructor (prefix = '') {
      this.prefix = prefix;
    }

    get (name: string, fallback = '') {
      return process.env[`VUE_APP${this.prefix}_${this.normalize(name)}`] || fallback;
    }

    // Convert "MyAwesomeString" to "MY_AWESOME_STRING"
    normalize (name: string) {
      const r = /([a-z])([A-Z])/g; // Matches lowercase letter followed by uppercase letter
      return name
        .replace(r, '$1_$2')
        .split('_')
        .filter(x => x.trim())
        .join('_')
        .toUpperCase();
    }
}
