





















import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { IDataRequest } from '@einfachgast/shared';
import { firebaseWrapper } from '@/firebase';
import { DBCollection } from '@/DBCollections';
import { IDataRequestActionLog } from '@/interfaces/i-data-request-action-logs';
import { plainToClass } from 'class-transformer';
import { DataRequestActionLog } from '@/models/data-request-action-log';

@Component({
  name: 'DataRequestLogs',
})
export default class DataRequestLogs extends Vue {
  loading = true;
  logs: IDataRequestActionLog[] = [];
  logsSnapshot: () => void;

  get displayLogs (): IDataRequestActionLog[] {
    return this.logs.concat([{
      userId: this.dataRequest.ownerId,
      action: 'Created data Request',
      createdAt: this.dataRequest.createdAt,
    }]);
  }

  @Prop()
  dataRequestId: string;

  @Prop()
  dataRequest: IDataRequest;

  async mounted () {
    const logsCollection = firebaseWrapper.db
      .collection(DBCollection.DataRequests)
      .doc(this.dataRequestId)
      .collection(DBCollection.DataRequestLogs)
      .orderBy('createdAt', 'desc');

    this.logsSnapshot = logsCollection.onSnapshot(snap => {
      this.logs = snap.docs.map(x => plainToClass(DataRequestActionLog, x.data() as IDataRequestActionLog));
    });

    await logsCollection.get();
    this.loading = false;
  }

  beforeDestroy () {
    this.logsSnapshot();
  }
}
