import { ISuperAdminDataItem } from '@/interfaces';
import { SuperAdminDateGroups } from '@/enums/super-admin-date-groups';

export class SuperAdminDateHelper {
  public allGuestsVisitors (ownerResults: ISuperAdminDataItem[]) {
    const groupedData = this.defaultGroupingData(ownerResults);
    return groupedData.reduce((a, b) => a + b.count, 0);
  }

  public getFormattedGroupdStatsData (ownerResults: ISuperAdminDataItem[], groupedBy: SuperAdminDateGroups) {
    let formattedDateArray: { date: string; count: number } [] = [];
    if (groupedBy === 'day') {
      formattedDateArray = this.groupedByDate(ownerResults);
    } else if (groupedBy === 'month') {
      formattedDateArray = this.groupedByMonth(ownerResults);
    } else if (groupedBy === 'year') {
      formattedDateArray = this.groupedByYear(ownerResults);
    }
    return formattedDateArray;
  }

  private groupedByDate (ownerResults: ISuperAdminDataItem[]) {
    const groupedData = this.defaultGroupingData(ownerResults);
    if (!groupedData || groupedData.length <= 0) {
      return [];
    }
    const dateRange = this.getDates(groupedData[0].date, groupedData[groupedData.length - 1].date);
    const formattedDateArray = [];
    for (const date of dateRange) {
      formattedDateArray.push({
        date: date.toLocaleDateString(),
        count: this.getCountByGroupDate(groupedData, date, SuperAdminDateGroups.Day),
      });
    }
    return formattedDateArray;
  }

  private groupedByMonth (ownerResults: ISuperAdminDataItem[]) {
    const groupedData = this.defaultGroupingData(ownerResults);
    const dateRange = this.getMonths(groupedData[0].date, groupedData[groupedData.length - 1].date);
    const formattedDateArray = [];
    for (const date of dateRange) {
      formattedDateArray.push({
        date: `${date.getFullYear()}.${(date.getMonth() + 1)}`,
        count: this.getCountByGroupDate(groupedData, date, SuperAdminDateGroups.Month),
      });
    }
    return formattedDateArray;
  }

  private groupedByYear (ownerResults: ISuperAdminDataItem[]) {
    const groupedData = this.defaultGroupingData(ownerResults);
    const dateRange = this.getYears(groupedData[0].date, groupedData[groupedData.length - 1].date);
    const formattedDateArray = [];
    for (const date of dateRange) {
      formattedDateArray.push({
        date: date.getFullYear().toString(),
        count: this.getCountByGroupDate(groupedData, date, SuperAdminDateGroups.Year),
      });
    }
    return formattedDateArray;
  }

  private defaultGroupingData (ownerResults: ISuperAdminDataItem[]) {
    const groupedHistoryOfAll: {[key: string]: number} = {};
    for (const data of ownerResults) {
      for (const dateKey in data.mergedStatsObj) {
        groupedHistoryOfAll[dateKey] || (groupedHistoryOfAll[dateKey] = 0);
        groupedHistoryOfAll[dateKey] += data.mergedStatsObj[dateKey];
      }
    }
    const mappedData: Array<{date: Date; count: number}> = [];
    for (const groupedKey in groupedHistoryOfAll) {
      mappedData.push({
        date: new Date(groupedKey),
        count: groupedHistoryOfAll[groupedKey],
      });
    }
    mappedData.sort((a, b) => { return a.date.getTime() - b.date.getTime(); });
    return mappedData;
  }

  private getCountByGroupDate (mappedData: Array<{date: Date; count: number}>, date: Date, groupDate: SuperAdminDateGroups) {
    if (groupDate === SuperAdminDateGroups.Day) {
      return mappedData.find(x => x.date.toLocaleDateString() === date.toLocaleDateString())?.count | 0;
    } else if (groupDate === SuperAdminDateGroups.Month) {
      return mappedData
        .filter(x => x.date.getFullYear() === date.getFullYear() && x.date.getMonth() === date.getMonth())
        .reduce((a, b) => a + b.count, 0) | 0;
    } else if (groupDate === SuperAdminDateGroups.Year) {
      return mappedData
        .filter(x => x.date.getFullYear() === date.getFullYear())
        .reduce((a, b) => a + b.count, 0) | 0;
    }
    return 0;
  }

  private getMonths (startDate: Date, stopDate: Date) {
    const dateArray = [];
    const currentDate = new Date(startDate.getTime());
    // eslint-disable-next-line
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate.toString()));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return dateArray;
  }

  private getYears (startDate: Date, stopDate: Date) {
    const dateArray = [];
    const currentDate = new Date(startDate.getTime());
    // eslint-disable-next-line
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate.toString()));
      currentDate.setFullYear(currentDate.getFullYear() + 1);
    }
    return dateArray;
  }

  private getDates (startDate: Date, stopDate: Date) {
    const dateArray = [];
    const currentDate = new Date(startDate.getTime());
    // eslint-disable-next-line
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate.toString()));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }
}
