






import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { DataRequestStatus } from '@einfachgast/shared';

@Component({
  name: 'DataRequestProgressBar',
})
export default class DataRequestProgressBar extends Vue {
  @Prop()
  status: DataRequestStatus;

  get progress () {
    if (this.status === DataRequestStatus.WaitingForApproval) {
      return 25;
    }

    if (this.status === DataRequestStatus.Approved) {
      return 50;
    }

    if (this.status === DataRequestStatus.WaitingForDownload) {
      return 75;
    }

    if (this.status === DataRequestStatus.Complete || this.status === DataRequestStatus.Denied) {
      return 100;
    }
    return 0;
  }

  get type () {
    if (this.status === DataRequestStatus.Denied) {
      return 'is-danger';
    }
    if (this.status === DataRequestStatus.Complete) {
      return 'is-success';
    }
    return 'is-warning';
  }
}
