































import Component from 'vue-class-component';
import Vue from 'vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'LoggedOutWrapper',
  components: {
    EinfachGastLogo,
  },
})
export default class LoggedOutWrapper extends Vue {
  @Prop({ default: true })
  withLogo: boolean
}
