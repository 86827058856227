


















































































import { Component, Vue } from 'vue-property-decorator';
import { FirebaseFunctions, firebaseWrapper as fb } from '@/firebase';
import { ISuperAdminDataItem } from '@/interfaces';
import Chart from 'chart.js';
import { SuperAdminDateHelper } from '@/helpers/super-admin-date-helper';
import { SuperAdminDateGroups } from '@/enums/super-admin-date-groups';

@Component({
  name: 'SuperAdminDashboard',
  filters: {
    formatNumber (number: number) {
      return new Intl.NumberFormat([], {}).format(number);
    },
  },
})
export default class SuperAdminDashboard extends Vue {
  currentPage = 1;
  entriesPerPage = 30;
  loading = false;
  ownerResults: ISuperAdminDataItem[] = [];
  groupedSubscription: {[key: string]: number} = {};
  chart: Chart = null;
  superAdminDateHelper = new SuperAdminDateHelper();
  visitorsGroupedBy: SuperAdminDateGroups = SuperAdminDateGroups.Day;

  get allGuestsVisitors () {
    return this.superAdminDateHelper.allGuestsVisitors(this.ownerResults);
  }

  get inactiveOwnerAmount () {
    return this.ownerResults.filter(x => x.isInactiveOwner === true).length;
  }

  get avgAreasFromAllVenues () {
    if (this.ownerResults.length === 0) {
      return 0;
    }
    const allAvgAreas = this.ownerResults.reduce((a, b) => a + b.avgAreaCountOnAllVenues, 0);
    return Math.round((allAvgAreas / this.ownerResults.length) * 100) / 100;
  }

  async mounted () {
    this.loading = true;
    try {
      const superAdminDashboardDataFunc = fb.functions.httpsCallable(FirebaseFunctions.SuperAdminDashboardData);
      const superAdminDashboardData = await superAdminDashboardDataFunc();
      this.ownerResults = superAdminDashboardData.data;
      for (const dashboardData of this.ownerResults) {
        this.groupedSubscription[dashboardData.subscribedProductName] || (this.groupedSubscription[dashboardData.subscribedProductName] = 0);
        this.groupedSubscription[dashboardData.subscribedProductName]++;
      }
      this.initVisitorsChart();
      this.loading = false;
    } catch {
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Fehler beim Laden der SuperAdminDaten!',
        position: 'is-top',
        type: 'is-danger',
      });
    }
  }

  initVisitorsChart () {
    const ctx = (document.getElementById('visitorChart') as HTMLCanvasElement).getContext('2d');
    const formattedDateArray = this.superAdminDateHelper.getFormattedGroupdStatsData(this.ownerResults, this.visitorsGroupedBy);
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: formattedDateArray.map(x => x.date),
        datasets: [{
          label: 'Besucher',
          data: formattedDateArray.map(x => x.count),
          backgroundColor: [
            'rgba(66, 144, 206, 0.6)',
          ],
          borderColor: [
            'rgba(66, 144, 206, 1)',
          ],
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      },
    });
  }

  formatFirebaseDateTime (dateString: string) {
    const dateTime = new Date(dateString);
    return dateTime.toLocaleTimeString([], {
      year: 'numeric', month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit',
    });
  }
}
