import { TransformDate } from '@/decorators/transform-date-decorator';
import { DataRequestStatus, IDataRequest } from '@einfachgast/shared';
import { Expose } from 'class-transformer';

export class DataRequest implements IDataRequest {
  id: string;
  status: DataRequestStatus;
  ownerId: string;
  venueId: string;
  healthDepartmentContact: string;
  healthDepartmentPhone: string;
  healthDepartmentName: string;
  healthDepartmentEmail?: string;

  @TransformDate()
  createdAt: Date;

  @TransformDate()
  fromDate: Date;

  @TransformDate()
  toDate: Date;

  annotation?: string;
  secret?: string;
}
