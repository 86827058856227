

























































import Vue from 'vue';
import Component from 'vue-class-component';
import { VueEnvHelper } from '@/helpers/vue-envvar-helper';
import cookieHelper from '@/helpers/cookie-helper';

@Component
export default class DeveloperHint extends Vue {
  private envHelper = new VueEnvHelper()
  private dismissed = false

  mounted () {
    // Initially no cookie is set but the dev hint should be visible
    this.dismissed = cookieHelper.has('devhint-dismissed')
      ? !!cookieHelper.get('devhint-dismissed')
      : false;
  }

  get IsVisible () {
    return !this.IsProduction && !this.IsDismissed;
  }

  get IsDismissed () {
    return this.dismissed;
  }

  get Branch () {
    return this.envHelper.get('GIT_BRANCH');
  }

  get BuildDate () {
    return this.envHelper.get('BUILD_DATE');
  }

  get Sha () {
    return this.envHelper.get('GIT_SHA');
  }

  get IsProduction () {
    return this.Environment === 'production';
  }

  get CssClasses () {
    const cssClasses: { [key: string]: string | boolean | number } = {};

    // Set the `is-{primary|warning|danger|...}` class
    cssClasses[`is-${this.IsColor}`] = true;

    return cssClasses;
  }

  /**
   * Return one of Bulmas color names depending on the environment
   */
  get IsColor () {
    switch (this.Environment) {
      case 'staging':
        return 'danger';
    }
    return 'warning';
  }

  get Environment () {
    // eslint-disable-next-line no-undef
    return (this.envHelper.get('ENVIRONMENT') || process.env.NODE_ENV).toLowerCase();
  }

  async copyShaToClipboard () {
    if (!navigator.clipboard) {
      this.$buefy.toast.open({
        message: 'Your browser does not support copy to clipboard feature!',
        type: 'is-danger',
      });
      return;
    }
    await navigator.clipboard.writeText(this.Sha);
    this.$buefy.toast.open('Copied to clipboard');
  }

  dismiss () {
    this.dismissed = true;
    cookieHelper.set('devhint-dismissed', 'true');
  }
}
