




















import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { RouteNames } from '@/router';

@Component({
  name: 'LoginHeaderToggle',
})
export default class LoginHeaderToggle extends Vue {
  @Prop()
  status: 'login' | 'registration';

  async goToLogin () {
    if (this.status === 'login') {
      return;
    }
    await this.$router.push({ name: RouteNames.Login });
  }
}
