
















































import { Component, Prop } from 'vue-property-decorator';
import LoginHeaderToggle from '@/components/login/login-header-toggle.vue';
import LoggedOutWrapper from '@/components/logged-out-wrapper.vue';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { LoginData } from '@/models/login-data';
import { ILoginData } from '@/interfaces';

@Component({
  name: 'Login',
  components: {
    LoggedOutWrapper,
    LoginHeaderToggle,
  },
})
export default class Login extends mixins(FormFunctions) {
  loading = false;
  loginData: ILoginData = new LoginData();

  @Prop()
  redirectUrl: string;

  @Prop()
  verified: string

  async beforeCreate () {
    if (this.$auth.user) {
      await this.$router.push({ path: '/' });
      return;
    }
    this.loading = false;
  }

  async login () {
    if (!await this.validate(this.loginData)) {
      return;
    }

    try {
      await this.$auth.login(this.loginData);
      await this.$router.push(this.redirectUrl || '/');
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Falsches Passwort oder E-Mail',
        position: 'is-top',
        type: 'is-danger',
      });
    }
  }
}
