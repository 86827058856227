/* eslint-disable @typescript-eslint/no-shadow */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/views/Login.vue';
import NotFound from '@/components/not-found.vue';
import DataRequests from '@/views/DataRequests.vue';
import DataRequestDetails from '@/views/DataRequestDetails.vue';
import SuperAdminDashboard from '@/views/SuperAdminDashboard.vue';
import { AuthModule } from '@/store/modules/auth';
import { store } from '@/store';
import { getModule } from 'vuex-module-decorators';

Vue.use(VueRouter);

enum RouteNames {
  Login = 'login',
  NotFound = 'not-found',
  Dashboard = 'dashboard',
  DataRequests = 'data-requests',
  DataRequestDetails = 'data-request-details',
}

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: RouteNames.Login,
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: '/notfound',
    name: RouteNames.NotFound,
    component: NotFound,
  },
  {
    path: '/',
    name: RouteNames.DataRequests,
    component: DataRequests,
    meta: {
      menuTitle: 'Downloadanfragen',
      menuIcon: 'download',
      menuSortOrder: 11,
    },
  },
  {
    path: '/dashboard',
    name: RouteNames.Dashboard,
    component: SuperAdminDashboard,
    meta: {
      menuTitle: 'Dashboard',
      menuIcon: 'chart-line',
      menuSortOrder: 22,
    },
  },
  {
    path: '/datarequest/:dataRequestId',
    name: RouteNames.DataRequestDetails,
    component: DataRequestDetails,
    props: true,
  },
];

const router = new VueRouter({
  routes,
});

const auth = getModule(AuthModule, store);

router.beforeEach((to, from, next) => {
  if (to.meta.permission && !auth.permissions.has(to.meta.permission)) {
    next({ name: RouteNames.NotFound });
    return;
  }
  // Enforce login
  if (!to.meta.public && !auth.user) {
    next({ name: RouteNames.Login, params: { redirectUrl: to.fullPath } });
    return;
  }

  document.body.className = to.name?.toLowerCase();
  if (to.meta?.blueBg) {
    document.body.classList.add('blueBg');
  } else {
    document.body.classList.remove('blueBg');
  }
  next();
});

export { routes, RouteNames };
export default router;
