import { Roles } from '@/enums/roles';
import firebase from 'firebase/app';

export class PermissionResolver {
  private _roles: Roles[] = [];

  public setRolesByTokenClaims (claims: firebase.FirebaseIdToken | { [key: string]: unknown }) {
    if (claims.isAdmin) {
      this._roles.push(Roles.isAdmin);
    }
  }

  public setRolesByToken (token?: firebase.auth.IdTokenResult) {
    if (token) {
      this.setRolesByTokenClaims(token.claims);
    }
  }

  public has (role: Roles) {
    return this._roles.indexOf(role) !== -1;
  }
}
