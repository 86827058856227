import { Transform } from 'class-transformer';

export function TransformDate() {
  return Transform((value) => {
    if (value && Object.keys(value).indexOf('_seconds') !== -1) {
      return new Date(value._seconds as number * 1000);
    }
    else if (value && Object.keys(value).indexOf('seconds') !== -1) {
      return new Date(value.seconds as number * 1000);
    } else if (!value) {
      return value;
    }
    return new Date(value);
  }, { toClassOnly: true });
}