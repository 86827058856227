import { Action, config, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import firebase from 'firebase/app';
import { ILoginData } from '@/interfaces';
import { FirebaseFunctions, firebaseWrapper as fb } from '@/firebase';
import { IUserProfile } from '@/interfaces/i-user-profile';
import { UserProfile } from '@/models/user-profile';
import { Roles } from '@/enums/roles';
import { PermissionResolver } from '@/helpers/permission-resolver';
import { IAuthStore } from '../interfaces/i-auth-module';
import { plainToClass } from 'class-transformer';

config.rawError = true;

@Module({ namespaced: true, name: 'auth' })
export class AuthModule extends VuexModule implements IAuthStore {
  private _user: firebase.User | null = null;
  private _permissions = new PermissionResolver();
  private _isVenueOwner = false;
  private _isAdmin = false;
  private _isVenueOwnerDeleted = false;
  private _trialExpiryDate = new Date();
  private _isBillingEnabled = false;
  private _userProfile: IUserProfile | null = null;
  private _venueSnapshot: (() => void) | null = null;
  private _pendingVisitsSnapshot: (() => void) | null = null;

  get user () {
    return this._user;
  }

  get permissions () {
    return this._permissions;
  }

  @Mutation
  setUser (user: firebase.User) {
    this._user = user;
  }

  get userProfile () {
    return this._userProfile;
  }

  @Mutation
  setUserProfile (userProfile: IUserProfile) {
    this._userProfile = userProfile;
  }

  get isVenueOwner () {
    return this._isVenueOwner;
  }

  get isAdmin () {
    return this._isAdmin;
  }

  get isVenueOwnerDeleted () {
    return this._isVenueOwnerDeleted;
  }

  @Mutation
  setIsVenueOwner (isOwner: boolean) {
    this._isVenueOwner = isOwner;
  }

  @Mutation
  setIsAdmin (isAdmin: boolean) {
    this._isAdmin = isAdmin;
  }

  @Mutation
  setIsVenueOwnerDeleted (isOwnerDeleted: boolean) {
    this._isVenueOwnerDeleted = isOwnerDeleted;
  }

  get venueSnapshot () {
    return this._venueSnapshot;
  }

  get pendingVisitsSnapshot () {
    return this._pendingVisitsSnapshot;
  }

  @Mutation
  setVenueSnapshotUnsubscribe (venueSnapshot: () => void) {
    this._venueSnapshot = venueSnapshot;
  }

  @Mutation
  setPendingVisitsSnapshoUnsubscribe (pendingVisistsSnapshot: () => void) {
    this._pendingVisitsSnapshot = pendingVisistsSnapshot;
  }

  get trialExpiryDate () {
    return this._trialExpiryDate;
  }

  @Mutation
  setTrialExpiryDate (date: Date) {
    this._trialExpiryDate = date;
  }

  get isBillingEnabled () {
    return this._isBillingEnabled;
  }

  @Mutation
  setIsBillingEnabled (enabled: boolean) {
    this._isBillingEnabled = enabled;
  }

  get isRegistrationComplete () {
    return !!this.user;
  }

  @Action
  async initApp () {
    const user = fb.auth.currentUser;
    this.context.commit('setUser', user);
    await this.context.dispatch('parseClaims', { user });
    await this.context.dispatch('initUserProfile', user);
  }

  @Action
  async reInitApp () {
    await this.parseClaims({ user: fb.auth.currentUser, forceRefresh: true });
  }

  @Action
  async initUserProfile () {
    if (!this.user) {
      this.context.commit('setUserProfile', null);
      return;
    }
    try {
      const result = await fb.usersCollection.doc(this.user.uid).get();
      this.context.commit('setUserProfile', plainToClass<UserProfile, IUserProfile>(UserProfile, result.data() as IUserProfile));
    } catch {
      throw Error('Fetching user profile went wrong!');
    }
  }

  @Action
  unsubscribeVenueSnapshot () {
    this._venueSnapshot && this._venueSnapshot();
    this._pendingVisitsSnapshot && this._pendingVisitsSnapshot();
  }

  // actions
  @Action
  async login (loginData: ILoginData) {
    const { user } = await fb.auth.signInWithEmailAndPassword(loginData.email, loginData.password);
    await this.context.dispatch('parseClaims', { user });
    if (!this._permissions.has(Roles.isAdmin)) {
      await this.context.dispatch('logout');
      throw new Error('Wrong login data');
    }
    // store user object
    this.context.commit('setUser', user);
  }

  @Action
  async parseClaims (payload?: { user: firebase.User; forceRefresh?: boolean }) {
    // Check claims
    const token = await payload?.user?.getIdTokenResult(payload?.forceRefresh);
    this._permissions.setRolesByToken(token);
    this.context.commit('setIsAdmin', this._permissions.has(Roles.isAdmin));
  }

  @Action
  async signup (registrationData: ILoginData) {
    // sign user up
    const userRegistration = fb.functions.httpsCallable(FirebaseFunctions.RegisterVenueOwner);
    await userRegistration(registrationData);

    // log user in
    await fb.auth.signInWithEmailAndPassword(registrationData.email, registrationData.password);
    const user = fb.auth.currentUser;
    await this.context.dispatch('parseClaims', { user });

    // send verification mail to user
    const parsedUrl = new URL(window.location.href);
    await user.sendEmailVerification({ url: `${parsedUrl.protocol}//${parsedUrl.host}` });

    this.context.commit('setUser', user);
  }

  @Action
  async logout () {
    await fb.auth.signOut();
    await this.context.dispatch('parseClaims', null);
  }

  @Action
  async sendResetPasswordMail (email: string) {
    return await fb.auth.sendPasswordResetEmail(email);
  }

  @Action
  async reauthenticateUser (currentPassword: string) {
    // related to https://medium.com/@ericmorgan1/change-user-email-password-in-firebase-and-react-native-d0abc8d21618
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email, currentPassword);
    return await user.reauthenticateWithCredential(cred);
  }
}
