import { IAuthStore } from '@/interfaces';
import Vue from 'vue';
import Vuex from 'vuex';
import { AuthModule } from './modules/auth';

Vue.use(Vuex);

export interface IStore {
  auth: IAuthStore;
}

const store = new Vuex.Store<IStore>({
  modules: {
    auth: AuthModule,
  },
});

export { store };
