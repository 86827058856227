















import Vue from 'vue';
import Component from 'vue-class-component';
import { IVenue } from '@einfachgast/shared';
import { FirebaseFunctions, firebaseWrapper } from '@/firebase';
import debounce from 'lodash/debounce';

@Component({
  name: 'VenueTypeahead',
})
export default class VenueTypeahead extends Vue {
  loading = false;
  venuesWithRequests: IVenue[] = null;
  venueNameSearchString: string = null;

  getVenuesByNamePartDebounced (namePart: string) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const debounced = debounce(this.getVenuesByNamePart, 500);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    debounced(namePart);
  }

  async getVenuesByNamePart (namePart: string) {
    if (!namePart.length) {
      this.venuesWithRequests = [];
      return;
    }
    this.loading = true;
    const myCall = firebaseWrapper.functions.httpsCallable(FirebaseFunctions.GetVenueWithDataRequests);
    this.venuesWithRequests = (await myCall(namePart)).data as IVenue[];
    this.loading = false;
  }
}
