













































import { Component } from 'vue-property-decorator';
import LoginHeaderToggle from '@/components/login/login-header-toggle.vue';
import LoggedOutWrapper from '@/components/logged-out-wrapper.vue';
import VenueTypeahead from '@/components/venues/venue-typeahead.vue';
import DataRequestProgressBar from '@/components/data-request/data-request-progress-bar.vue';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { firebaseWrapper } from '@/firebase';
import { DBCollection } from '@/DBCollections';
import firebase from 'firebase/app';
import { RouteNames } from '@/router';
import { DataRequestStatus, IVenue, IDataRequest } from '@einfachgast/shared';
import { plainToClass } from 'class-transformer';
import { DataRequest } from '@/models/data-request';

@Component({
  name: 'DataRequests',
  components: {
    LoggedOutWrapper,
    LoginHeaderToggle,
    VenueTypeahead,
    DataRequestProgressBar,
  },
})
export default class DataRequests extends mixins(FormFunctions) {
  loading = true;
  openRequests: IDataRequest[] = [];
  venues: IVenue[] = [];
  filterStatus: DataRequestStatus | 'all' = DataRequestStatus.WaitingForApproval;
  selectedVenueId: string = null;
  unsubscribeReuqestsSnapshot: () => void;

  async mounted () {
    try {
      await this.loadRequests();
      await this.loadVenues();
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-error',
      });
    }
  }

  beforeDestroy () {
    this.unsubscribeReuqestsSnapshot();
  }

  get statusOptions () {
    return ['all'].concat(Object.values(DataRequestStatus));
  }

  async onVenueSelected () {
    this.filterStatus = 'all';
    await this.loadRequests();
  }

  async loadRequests () {
    let requestQuery: firebase.firestore.Query<firebase.firestore.DocumentData> = firebaseWrapper.db
      .collection(DBCollection.DataRequests);

    if (this.filterStatus !== 'all') {
      requestQuery = requestQuery.where('status', '==', this.filterStatus);
    }

    if (this.selectedVenueId) {
      requestQuery = requestQuery.where('venueId', '==', this.selectedVenueId);
    }
    if (this.unsubscribeReuqestsSnapshot) {
      this.unsubscribeReuqestsSnapshot();
    }
    this.unsubscribeReuqestsSnapshot = requestQuery.onSnapshot((snapshot) => {
      this.loading = true;
      const loadedOpenRequests: IDataRequest[] = [];
      snapshot.docs.forEach((doc: firebase.firestore.DocumentData) => {
        const requestData = doc.data() as IDataRequest;
        requestData.id = doc.id;
        loadedOpenRequests.push(plainToClass(DataRequest, requestData));
      });
      this.openRequests = loadedOpenRequests;
      this.loading = false;
    });
    await requestQuery.get();
  }

  async loadVenues () {
    this.venues = [];
    const querySnapshot = await firebaseWrapper.db
      .collection(DBCollection.Venues)
      .get();
    this.venues = [];
    querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
      const venue = doc.data() as IVenue;
      venue.id = doc.id;
      this.venues.push(venue);
    });
  }

  async openDataRequest (dataRequest: IDataRequest) {
    await this.$router.push({ name: RouteNames.DataRequestDetails, params: { dataRequestId: dataRequest.id } });
  }

  getVenueLabelById (venueId: string) {
    const venue = this.venues.find(v => v.id === venueId);
    return venue?.label || '';
  }
}
