







































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { FirebaseFunctions, firebaseWrapper } from '@/firebase';
import { isEmail } from 'class-validator';

@Component({
  name: 'SendMailModal',
})

export default class SendMailModal extends Vue {
  emailSending = false;
  targetEmailWorkingCopy = '';

  @Prop({ default: '' })
  targetEmail: string;

  @Prop()
  dataRequestId: string;

  mounted () {
    this.targetEmailWorkingCopy = this.targetEmail;
  }

  async sendDownloadLink () {
    if (!isEmail(this.targetEmailWorkingCopy)) {
      return;
    }
    this.emailSending = true;
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset();
    try {
      const sendEmailFunction = firebaseWrapper.functions.httpsCallable(FirebaseFunctions.SendHealthDepartmentMail);
      await sendEmailFunction({ requestId: this.dataRequestId, email: this.targetEmailWorkingCopy, timezoneOffset: timezoneOffset * -1 });
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Erfolgreich versendet!',
        position: 'is-top',
        type: 'is-success',
      });
      this.$emit('close');
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.emailSending = false;
  }
}
