import { VueEnvHelper } from '@/helpers/vue-envvar-helper';

export class FirebaseConfiguration {
  apiKey = '';
  appId = '';
  authDomain = '';
  databaseURL = '';
  messagingSenderId = '';
  projectId = '';
  storageBucket = '';
  measurementId = '';

  constructor () {
    const envHelper = new VueEnvHelper('_FIREBASE');
    Object.keys(this).forEach(key => {
      const val = envHelper.get(key);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any)[key] = val;
    });
  }
}
