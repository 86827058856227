import { IUserProfile } from '@/interfaces/i-user-profile';
import { IsEmail, MinLength } from 'class-validator';
import firebase from 'firebase/app';
import { Expose, Transform } from 'class-transformer';

export class UserProfile implements IUserProfile {
  @MinLength(2, { message: 'Bitte geben sie mindestens zwei Zeichen ein' })
  public companyName: string;

  @MinLength(2, { message: 'Bitte geben sie mindestens zwei Zeichen ein' })
  public firstname: string;

  @MinLength(2, { message: 'Bitte geben sie mindestens zwei Zeichen ein' })
  public lastname: string;

  @MinLength(5, { message: 'Bitte geben sie mindestens fünf Zeichen ein' })
  public phone: string;

  @MinLength(2, { message: 'Bitte geben sie mindestens zwei Zeichen ein' })
  public street: string;

  @MinLength(4, { message: 'Bitte geben sie mindestens vier Zeichen ein' })
  public zipCode: string;

  @MinLength(2, { message: 'Bitte geben sie mindestens zwei Zeichen ein' })
  public city: string;

  @Expose()
  @Transform(value => value || 'DE', { toClassOnly: true })
  country: string;

  @IsEmail({}, { message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein' })
  public email: string;

  public stripeId: string;
  public userId?: string;
  public isDeleted: boolean;
  public deletedAt: firebase.firestore.Timestamp;
  public createdAt: firebase.firestore.Timestamp;
  public lastSeenAt: firebase.firestore.Timestamp;
  public trialEnd: firebase.firestore.Timestamp;
  public remainingVisits?: number;
}
