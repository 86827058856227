/* eslint-disable import/no-unassigned-import */
import Vue from 'vue';
import App from './App.vue';
import { AuthPlugin } from './plugins/auth-plugin';
import '@/assets/styles.scss';
import router, { RouteNames } from './router';
import { store } from './store';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import { firebaseWrapper } from './firebase';
import firebase from 'firebase';
import { getModule } from 'vuex-module-decorators';
import { AuthModule } from './store/modules/auth';
import * as Sentry from '@sentry/vue';

Vue.config.productionTip = false;
Vue.use(AuthPlugin);
Vue.use(Buefy);
const authStore = getModule(AuthModule, store);

(() => {
  firebaseWrapper.initialize();
  firebaseWrapper.auth.onAuthStateChanged((user: firebase.User) => {
    authStore.initApp().then(async () => {
      Sentry.setUser({ id: authStore.user?.uid });
      // at this time router.currentRoute is not init
      if (window.location.pathname?.toLowerCase() === '/password-reset') {
        await router.push({ name: RouteNames.NotFound });
      } else if (!user) {
        if (window.location.pathname.toLowerCase() === '/register') {
          await router.push({ name: RouteNames.NotFound });
        } else {
          // when user logged out, the route set everytime to login (BEFORE Vue init/mount)
          await router.push({ name: RouteNames.Login });
        }
      }
      new Vue({
        router,
        store,
        render: h => h(App),
      }).$mount('#app');
      // have to check it here, because after this function the "router" is not really initialized yet
      // when user is logged in and try login or register in Url => the user redirect to Dashboard
      if (user &&
        (
          router.currentRoute.name?.toLowerCase() === 'login'
          || router.currentRoute.name?.toLowerCase() === 'registration'
        )
      ) {
          await router.push({ name: RouteNames.Dashboard });
      }
      // when user is not logged in and try call direct register => the register page will load
      // all other Urls redirect to login
      if (!user && router.currentRoute.name?.toLowerCase() !== 'login') {
        if (router.currentRoute.name?.toLowerCase() === 'registration'
        || router.currentRoute.name?.toLowerCase() === 'passwordreset') {
          return;
        }
        await router.push({ name: RouteNames.Login });
      }
    }).catch((e) => {
      console.log(e);
      alert(e);
    });
  });
})();
