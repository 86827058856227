












import Vue from 'vue';
import Component from 'vue-class-component';
import Sidebar from '@/components/sidebar.vue';
import DeveloperHint from '@/components/hints/developer-hint.vue';
import { firebaseWrapper as fb } from '@/firebase';
import firebase from 'firebase/app';

@Component({
  name: 'App',
  components: {
    DeveloperHint,
    Sidebar,
  },
})
export default class App extends Vue {
  loading = true;
  licenseExpiry: Date = null;
  unsubscribeVenueOwnerListener: () => void = null;
  unsubscribeSubscriptionsListener: () => void = null;

  mounted () {
    fb.auth.onAuthStateChanged((user: firebase.User) => {
      if (!user) {
        // on logout
      }
    });
    // Muss hier so gemacht werden, da die App.vue gerendert wird, obwohl initApp (und somit initVenues) zu diesem Zeitpunkt noch nicht durch ist.
    // – OBWOHL (laut main.ts) ERST gerendert wird, wenn initApp fertig ist.
    // Dadruch sind am Anfang die Venues immer kurz leer und dadurch würde sonst immer der SetupHint für kurze Zeit angezeigt werden.
    if (this.$auth.userProfile && this.$auth.isRegistrationComplete) {
      // noop
    }
  }

  get showNav (): boolean {
    // No logged in User
    if (!this.$auth.user) { // || !this.$auth.user.emailVerified) {
      return false;
    }
    return true;
  }
}
