

















































































































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { firebaseWrapper } from '@/firebase';
import { DataRequestStatus, IVenue, IDataRequest } from '@einfachgast/shared';
import { DBCollection } from '@/DBCollections';
import { IVenueOwner } from '@/interfaces';
import firebase from 'firebase/app';
import DataRequestLogs from '@/components/data-request/data-request-logs.vue';
import SendMailModal from '@/components/data-request/send-mail-modal.vue';
import DataRequestProgressBar from '@/components/data-request/data-request-progress-bar.vue';
import { DataRequest } from '@/models/data-request';
import { plainToClass } from 'class-transformer';

@Component({
  name: 'DataRequestDetails',
  components: {
    DataRequestLogs,
    SendMailModal,
    DataRequestProgressBar,
  },
})
export default class DataRequestDetails extends mixins(FormFunctions) {
  loading = true;
  showSendEmailModal = false;
  venue: IVenue = null;
  dataRequest: IDataRequest = null;
  venueOwner: IVenueOwner = null;
  targetEmail = '';
  unsubDataRequestSnap: () => void;

  @Prop()
  dataRequestId: string;

  get buttonText () {
    if (this.dataRequest.status === DataRequestStatus.WaitingForApproval) {
      return 'Anfrage verifizieren';
    }

    if (this.dataRequest.status === DataRequestStatus.Approved) {
      return 'Downloadlink versenden';
    }

    return '';
  }

  get isWaitingForApproval () {
    return this.dataRequest.status === DataRequestStatus.WaitingForApproval;
  }

  get isApproved () {
    return this.dataRequest.status === DataRequestStatus.Approved;
  }

  get fromDate (): string {
    return this.dataRequest.fromDate?.toLocaleString();
  }

  get toDate (): string {
    return this.dataRequest.toDate?.toLocaleString();
  }

  get isComplete () {
    return this.dataRequest?.status === DataRequestStatus.Complete;
  }

  get isDenied () {
    return this.dataRequest?.status === DataRequestStatus.Denied;
  }

  isStepCompleted (stepName: DataRequestStatus) {
    return stepName === this.dataRequest.status;
  }

  async mounted (): Promise<void> {
    this.loading = true;
    try {
      await this.loadDataRequest();
      await this.loadVenue();
      await this.loadVenueOwner();
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }

  beforeDetroy () {
    this.unsubDataRequestSnap();
  }

  async loadDataRequest () {
    // get reference
    const dataRequestDoc = firebaseWrapper.db
      .collection(DBCollection.DataRequests)
      .doc(this.dataRequestId);

    // Create snapshot listener
    this.unsubDataRequestSnap = dataRequestDoc.onSnapshot((snapshot) => {
      if (!snapshot.exists) {
        throw new Error('Requested request does not exist');
      }
      this.dataRequest = plainToClass(DataRequest, snapshot.data() as IDataRequest);
    });

    // Peform get to trigger snapshot listener
    await dataRequestDoc.get();
  }

  async loadVenue () {
    const venue = await firebaseWrapper.db
      .collection(DBCollection.Venues)
      .doc(this.dataRequest.venueId)
      .get();
    if (!venue.exists) {
      throw new Error('Requested venue does not exist');
    }
    this.venue = venue.data() as IVenue;
  }

  async loadVenueOwner () {
    const venueOwner = await firebaseWrapper.db
      .collection(DBCollection.VenueOwners)
      .doc(this.venue.ownerId)
      .get();
    if (!venueOwner.exists) {
      throw new Error('Requested venueOwner does not exist');
    }
    this.venueOwner = venueOwner.data() as IVenueOwner;
  }

  send () {
    this.$buefy.toast.open({
      duration: 5000,
      message: 'wonderful',
      position: 'is-top',
      type: 'is-danger',
    });
  }

  async log (action: string) {
    await firebaseWrapper.db
      .collection(DBCollection.DataRequests)
      .doc(this.dataRequestId)
      .collection(DBCollection.DataRequestLogs)
      .add({
        userId: this.$auth.user.uid,
        action,
        createdAt: firebase.firestore.Timestamp.now(),
      });
  }

  async performActionDependigOnState () {
    if (this.dataRequest.status === DataRequestStatus.WaitingForApproval) {
      await firebaseWrapper.db
        .collection(DBCollection.DataRequests)
        .doc(this.dataRequestId)
        .update({
          status: DataRequestStatus.Approved,
        });
      await this.log('Approved data Request');
      return;
    }

    if (this.dataRequest.status === DataRequestStatus.Approved) {
      this.targetEmail = this.dataRequest.healthDepartmentEmail;
      this.showSendEmailModal = true;
    }
  }

  cancelRequest () {
    this.$buefy.dialog.confirm({
      message:
        'Damit wird die Anfrage abgelehnt und das Gesundheitsamt erhält keine Besucherdaten. Wirklich fortfahren?',
      onConfirm: async () => {
        this.loading = true;
        try {
          await firebaseWrapper.db
            .collection(DBCollection.DataRequests)
            .doc(this.dataRequestId)
            .update({ status: DataRequestStatus.Denied });

          await this.log('Denied data Request');
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: e.message,
            position: 'is-top',
            type: 'is-danger',
          });
        }
        this.loading = false;
      },
    });
  }
}
